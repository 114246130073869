import { useEffect, useState } from "react";
import PostListBox from '../Common/PostListBox';

export default (props) => {
  const {
    show,
    data = [],
    title = 'Title',
    onHide = () => { },
  } = props

  const [visible, setVisible] = useState(show);
  useEffect(() => {
    if (!visible) {
      console.log('hide');

      onHide?.()
    }
  }, [visible]);
  useEffect(() => {
    setVisible(show)
  }, [show]);
  return <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      zIndex: 9999,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.3)',
      transition: 'all 0.3s ease-in-out',
      opacity: visible ? 1 : 0,
      pointerEvents: visible ? 'auto' : 'none',
    }}
    onClick={e => {
      setVisible(false)
    }}
  >
    <div
      onClick={e => {
        e.stopPropagation()
      }}
      style={{
        width: '400px',
        maxWidth: '90vw',
        position: 'relative',
      }}
    >

      {visible && <div
        style={{
          position: 'absolute',
          top: ' -12px',
          right: '-12px',
          width: '36px',
          height: '36px',
          zIndex: 10001,
          backgroundColor: 'rgba(255,0,0,0.7)',
          borderRadius: '50%',
          cursor: 'pointer',
        }}
        onClick={() => {
          setVisible(false)
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            fontSize: '48px',
            fontFamily: 'monospace',
            fontWeight: '400',
            color: 'white',
          }}
        >×
        </div>
      </div>}
      {visible && <PostListBox
        title={title}
        theme={'Dot'}
        data={[...data]}
      />}
    </div>
  </div>
}