/*
 * Copyright (C) 2020 qnnp. All rights reserved.
 * 项目名称：zs.jscj.edu.cn
 * 文件名称：Home.js
 * Date: 2020/8/18 上午10:42
 * Author:qnnp
 */
import { Grid } from '@material-ui/core';
import axios from "axios";
import './Style/Home.css';
//https://sag1v.github.io/react-elastic-carousel/
import 'babel-polyfill';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Conf from '../Common/Conf';
import HomePI from "../Common/HomePI";
import POST from '../Common/POST';
import PostListBox from '../Common/PostListBox';
import HomeNavPopup from '../Components/HomeNavPopup';

function Home(props) {
  const history = useHistory()
  let [YxList, setYxList] = useState([])
  let [HomePostList1, setHomePostList1] = useState(<span />)
  let [HomePostList2, setHomePostList2] = useState(<span />)
  let [HomePostList3, setHomePostList3] = useState(<span />)
  let [HomePostList4, setHomePostList4] = useState(<span />)
  let [Ggxx, setGgxx] = useState(<span />)
  let [HomeSlider1, setHomeSlider1] = useState([])
  let [HomeSlider1Index, setHomeSlider1Index] = useState(0)
  useEffect(() => {
    props.setState(
      {
        title: '招生信息网',
        ShowSidebar: false,
      },
    )
    //获取公告信息
    POST('/article/findList', {
      type: 7,
      page: 1,
      strip: 8,
    })
      .then(data => {
        setGgxx(<PostListBox
          to={'/PostList/公告信息'}
          title={'公告信息'}
          data={data.data.data}
          theme={'ggxx'}
          id={'Ggxx'}
        />)
      })
    //报考指南
    POST('/article/findList', {
      type: 8,
      page: 1,
      strip: 16,
    })
      .then(data => {
        setHomePostList1(<PostListBox
          to={'/PostList/报考指南'}
          title={'报考指南'}
          data={data.data.data}
          theme={'Big'}
        />)
      })
    //招生计划
    POST('/article/findList', {
      type: 1,
      page: 1,
      strip: 8,
    })
      .then(data => {
        setHomePostList2(<PostListBox
          to={'/PostList/招生计划'}
          title={'招生计划'}
          data={data.data.data}
          theme={'Dot'}
        />)
      })
    //历年数据
    POST('/article/findList', {
      type: 2,
      page: 1,
      strip: 16,
    })
      .then(data => {
        setHomePostList3(<PostListBox
          to={'/PostList/考生问答'}
          title={'考生问答'}
          data={data.data.data}
          theme={'Big'}
        />)
      })
    //历年数据
    POST('/article/findList', {
      type: 17,
      page: 1,
      strip: 8,
    })
      .then(data => {
        setHomePostList4(<PostListBox
          to={'/PostList/历年数据'}
          title={'历年数据'}
          data={data.data.data}
          theme={'Dot'}
        />)
      })
    //获取第一个幻灯片
    // POST('/other/otherList', {type: 13})
    //   .then(data => setHomeSlider1(data.data))
    axios.get('/jscj/settings/slider')
      .then(res => {
        setHomeSlider1(res.data)
      })
    //	获取院系介绍列表
    POST('/link/findList', { type: 9 })
      .then(data => {
        setYxList(data.data)
      })
  }, [])
  //首页幻灯片1JS
  useEffect(() => {
    window.HomeSlider1Timer = setTimeout(() => {
      if ((
        HomeSlider1.length - HomeSlider1Index
      ) > 1) {
        setHomeSlider1Index(HomeSlider1Index + 1)
      } else {
        setHomeSlider1Index(0)
      }
    }, 3500)
    return () => {
      clearTimeout(window.HomeSlider1Timer)
    }
  }, [
    HomeSlider1Index,
    HomeSlider1,
  ])
  const [videoSrc, setvideoSrc] = useState('');
  const [topNavData, setTopNavData] = useState({});
  const handleToTopNav = (item) => {
    console.log(item);

    switch (item.cn) {
      case "提前招生":
        setTopNavData({
          title: "提前招生",
          data: [
            {
              title: '2024年提前招生章程',
              url: '/Post/8/247',
              isUrl: false
            },
            {
              title: '2024年提前招生计划',
              url: '/Post/1/109',
              isUrl: false
            },
            {
              title: '2024年提前招生计划（第二轮）',
              url: '/Post/1/110',
              isUrl: false
            },
          ]
        })
        break;
      case "职教高考":
        setTopNavData({
          title: "职教高考",
          data: [
            {
              title: '2024年中职职教高考招生简章',
              url: '/Post/8/248',
              isUrl: false
            },
            {
              title: '2024年中职职教高考招生计划',
              url: '/Post/1/111',
              isUrl: false
            },
          ]
        })
        break;
      case "普高招生":
        setTopNavData({
          title: "普高招生",
          data: [
            {
              title: '江苏财经职业技术学院2024年招生章程',
              url: '/Post/8/249',
              isUrl: false
            },
            {
              title: '2024年招生计划（江苏）',
              url: '/Post/1/112',
              isUrl: false
            },
            {
              title: '2024年招生计划（跨省）',
              url: '/Post/1/113',
              isUrl: false
            },
            {
              title: '2023年跨省分专业录取分数',
              url: '/Post/17/58',
              isUrl: false
            },
            {
              title: '2023年江苏省分专业录取分数',
              url: '/Post/17/57',
              isUrl: false
            },
          ]
        })
        break;
      default:
        history.push(item.to)
        break;
    }
  }
  //Home输出
  return (
    <Grid container className={'PageGridRoot'}>
      {/*首页大图*/}
      <Grid
        container
        item
        xs={12}
        style={{
          height: 0,
          paddingBottom: '30%',
          backgroundImage: `url(/homeBanner.jpg)`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          position: 'relative',
          zIndex: '2',
        }}
      />
      {/*首页按钮1*/}
      <Grid container item className={'PageGridRoot'} id={'HomeHeaderSubMenus'}>
        <Grid container item className={'PageGridMain'} justifyContent={'center'}>
          {(
            [
              {
                id: 1,
                // to: '/Year/2021',
                to: '/Post/1/109',
                cn: '提前招生',
                en: 'Early Enrollment',
              },
              {
                id: 2,
                // to: '/Year/2021/DKZS',
                to: '/Post/1/111',
                cn: '职教高考',
                // cn: '对口招生',
                en: 'Counterpart Enrollment',
              },
              {
                id: 3,
                to: '/PostList/招生计划',
                cn: '普高招生',
                en: 'Unified Recruitment',
              },
              // {
              //   id: 6,
              //   // to: '/PostList/招生计划',
              //   to: '/Post/1/113',
              //   cn: '外省招生',
              //   en: 'Provincial Enrollment',
              // },

              // {
              //   to: '/PostList/招生计划',
              //   cn: '招生计划',
              //   en: 'Enrollment Plan',
              // },
              // {
              //   to: '/PostList/录取信息',
              //   cn: '录取信息',
              //   en: 'Admission Information',
              // },
              // {
              //   to: '/Query',
              //   cn: '信息查询',
              //   en: 'Information Service',
              // },
              // {
              //   to: '/Guestbook',
              //   cn: '在线留言',
              //   en: 'Leave a message',
              // },
            ]
          ).map(menu =>
            <Grid
              key={menu.to}
              container
              item
              xs={Conf().MobileDevice
                ? 3
                : 12}
              className={'HomeHeaderSubMenusItem'}
            >
              <Link to={menu.to} onClick={e => {
                handleToTopNav(menu)
                e.preventDefault()
              }}>
                <span className={'CN'}>{menu.cn}</span>
                <span className={'EN'}>{menu.en}</span>
              </Link>
            </Grid>,
          )}
        </Grid>
      </Grid>
      <HomeNavPopup {...topNavData} show={!!topNavData?.title} onHide={() => setTopNavData({})} />
      {/*首页第一行*/}
      <Grid
        container item className={'PageGridMain'} style={{
          justifyContent: 'space-between',
          marginTop: '35px',
        }}
      >
        <Grid
          container item xs={Conf().MobileDevice
            ? 8
            : 12} style={{ padding: '15px' }}
        >
          {/*首页幻灯片1*/}
          <div
            className={'HomeSlider1'}
            style={{
              width: '100%',
              overflow: 'hidden',
              height: '0',
              paddingBottom: '50%',
              position: 'relative',
              borderRadius: '5px',
            }}
          >
            {[0, 1].map((_, i) => <div
              key={i}
              style={{
                position: 'absolute',
                top: '50%',
                [!i ? 'left' : 'right']: Conf().MobileDevice ? '24px' : '12px',
                transform: 'translateY(-50%)',
                zIndex: 9,
                color: '#fff',
                backgroundColor: 'rgba(0,0,0,0.5)',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                transition: 'all 0.5s',
                height: `${Conf().MobileDevice ? 48 : 36}px`,
                fontSize: `${Conf().MobileDevice ? 36 : 24}px`,
                userSelect: 'none',
                width: `${Conf().MobileDevice ? 48 : 36}px`,
                fontFamily: 'monospace',
              }}
              onClick={(e) => {
                let _i = !i ? HomeSlider1Index - 1 : HomeSlider1Index + 1
                _i = _i < 0 ? HomeSlider1.length - 1 : _i > HomeSlider1.length - 1 ? 0 : _i
                setHomeSlider1Index(_i)
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >{!i ? '«' : '»'}</div>
            </div>)}
            <div
              style={{
                width: (
                  HomeSlider1.length * 100
                ) + '%',
                height: '100%',
                marginLeft: '-' + (
                  HomeSlider1Index * 100
                ) + '%',
                transition: 'margin 0.5s',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            >
              {HomeSlider1.map(
                item =>
                  <div
                    key={item.id} style={{
                      width: (
                        100 / HomeSlider1.length
                      ) + '%',
                      height: '100%',
                      float: 'left',
                      backgroundImage: 'url(' + item.img + ')',
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      cursor: item.url ? 'pointer' : 'default',
                    }}
                    title={item.url ? '点击访问链接' : ''}
                    onClick={() => {
                      if (item.url) {
                        if (/^https?:/.test(item.url)) {
                          window.open(item.url)
                        } else if (/^video:/.test(item.url)) {
                          setvideoSrc(item.url.replace(/^video:/, ''))
                        } else {
                          history.push(item.url)
                          window.scrollTo(0, 0)
                        }
                      }
                    }}
                  />,
              )}
            </div>
          </div>
          {videoSrc && <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9,
          }}>
            <div style={{
              width: '90vw',
              height: 'min(calc(90vw * 9 / 16), calc(100vh - 80px))',
              backgroundColor: 'rgba(0,0,0,1)',
              position: 'relative',
            }}>
              <div style={{
                position: 'absolute',
                top: '-20px',
                right: '-20px',
                backgroundColor: 'red',
                color: 'white',
                width: '40px',
                height: '40px',
                fontSize: '24px',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                userSelect: 'none',
                zIndex: 99999999,
                borderRadius: '50%'
              }} onClick={() => setvideoSrc('')}>&times;</div>
              <video controls autoPlay loop style={{
                width: '100%',
                height: '100%',
                zIndex: 9999999,
                position: 'relative'
              }} >
                <source src={videoSrc} type={'video/mp4'} />
              </video>
            </div>
          </div>}
        </Grid>
        <Grid
          container item xs={Conf().MobileDevice
            ? 4
            : 12}
          style={{ padding: '15px' }}
        >
          {/*公告信息展示*/}
          {Ggxx}
        </Grid>
      </Grid>
      {/*	首页第二行*/}
      {/* <Grid
        container item className={'PageGridRoot'} style={{
          backgroundColor: '#e6e6e6',
          marginTop: '30px',
          marginBottom: '30px',
          backgroundImage: 'url(/bgs/bg1.jpg)',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Grid
          container item className={'PageGridRoot'} style={{
            justifyContent: 'center',
            backgroundColor: 'rgba(0,0,0,0.7)',
          }}
        >
          <Grid container item className={'PageGridMain'}>
            {/*首页第二行图表列表* /}
            {Conf().MobileDevice ? <Grid item xs={2} /> : null}
            {(
              [
                {
                  id: 1,
                  to: '/Year/2021',
                  cn: '提前招生',
                  en: 'Early Enrollment',
                },
                {
                  id: 2,
                  to: '/Year/2021/DKZS',
                  cn: '职教高考',
                  // cn: '对口招生',
                  en: 'Counterpart Enrollment',
                },
                {
                  id: 3,
                  to: '/PostList/招生计划',
                  cn: '普高招生',
                  en: 'Unified Recruitment',
                },
                // {
                //   id: 4,
                //   to: '/PostList/招生计划',
                //   cn: '普高注册',
                //   en: 'Required Register',
                // },
                // {
                //   id: 5,
                //   to: '/SignUp',
                //   cn: '社会招生',
                //   en: 'Social Enrollment',
                // },
                {
                  id: 6,
                  to: '/PostList/招生计划',
                  cn: '外省招生',
                  en: 'Provincial Enrollment',
                },
              ]
            ).map(
              item =>
                <Grid
                  key={item.id}
                  item
                  container
                  // xs={Conf().MobileDevice ? 2 : 6}
                  xs={Conf().MobileDevice ? 2 : 6}
                  className={'HomeIconItems1'}
                >
                  <Link to={item.to} className={'HomeIconLink1'}>
                    <div className={'icon'} id={'icon_' + item.id} />
                    <div className={'CN'}>{item.cn}</div>
                    <div className={'EN'}>{item.en}</div>
                  </Link>
                </Grid>,
            )}
          </Grid>
        </Grid>
      </Grid> */}
      {/* 报考指南, 招生计划 */}
      <Grid
        container item className={'PageGridRoot'} style={{
          justifyContent: 'center',
        }}
      >

        <Grid
          container item
          className={'PageGridMain'}
          style={{
            // padding       : '15px',
            justifyContent: 'space-between',
          }}
        >

          <Grid
            container item xs={Conf().MobileDevice
              ? 8
              : 12} style={{ padding: '15px' }}
          >
            {HomePostList1}
          </Grid>

          <Grid
            container item xs={Conf().MobileDevice
              ? 4
              : 12}
            style={{ padding: '15px' }}
          >
            {HomePostList2}
          </Grid>
        </Grid>
      </Grid>
      {/* 考生问答, 历年数据*/}
      <Grid
        container item className={'PageGridRoot'} style={{
          justifyContent: 'center',
        }}
      >
        <Grid
          container item
          className={'PageGridMain'}
          style={{
            // padding       : '15px',
            justifyContent: 'space-between',
          }}
        >
          <Grid
            container item xs={Conf().MobileDevice
              ? 8
              : 12} style={{ padding: '15px' }}
          >
            {HomePostList3}
          </Grid>

          <Grid
            container item xs={Conf().MobileDevice
              ? 4
              : 12}
            style={{ padding: '15px' }}
          >
            {HomePostList4}
          </Grid>
        </Grid>
      </Grid>
      {/*	首页第五行*/}
      <Grid
        container item className={'PageGridRoot'} style={{
          justifyContent: 'center',
        }}
      >
        {YxList.map(item =>
          <span key={item.linksId} />,
        )}
      </Grid>
      {/* 专业介绍 */}
      <Grid container item className={'PageGridRoot'}>
        <Grid container item className={'PageGridMain'}>
          <Grid container item xs={12} style={{ padding: '15px' }}>
            <HomePI />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Home
